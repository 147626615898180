<template>
  <b-container fluid="sm">
    <b-row class="mt-2 mb-2" dir="RTL">
      <b-col cols="12" md="6">
        <b-card bg-variant="light">
          <b-input-group prepend="التاريخ" class="mt-2 mb-2">
            <b-form-datepicker
              v-model="letterData.information.date"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              locale="ar"
            ></b-form-datepicker>
          </b-input-group>
          <b-input-group prepend="الرقم الاشاري" class="mt-2 mb-2">
            <b-form-input
              v-model="$store.state.letterData.information.number"
            ></b-form-input>
          </b-input-group>
          <b-input-group prepend="اسم الشركة" class="mt-2 mb-2">
            <b-form-input
              v-model="$store.state.letterData.client.company"
            ></b-form-input>
          </b-input-group>
          <b-input-group prepend="عنوان الشركة" class="mt-2 mb-2">
            <b-form-input
              v-model="$store.state.letterData.client.address"
            ></b-form-input>
          </b-input-group>
          <b-input-group prepend="مدينة الشركة" class="mt-2 mb-2">
            <b-form-input
              v-model="$store.state.letterData.client.city"
            ></b-form-input>
          </b-input-group>
          <b-input-group prepend="بلد الشركة" class="mt-2 mb-2">
            <b-form-input
              v-model="$store.state.letterData.client.country"
            ></b-form-input>
          </b-input-group>
          <b-input-group prepend="موجهة الى" class="mt-2 mb-2">
            <b-form-input
              v-model="$store.state.letterData.client.custom1"
            ></b-form-input>
          </b-input-group>
          <b-input-group prepend="الوظيفة" class="mt-2 mb-2">
            <b-form-input
              v-model="$store.state.letterData.client.custom2"
            ></b-form-input>
          </b-input-group>
          <b-input-group prepend="محتوى" class="mt-2 mb-2">
            <b-form-textarea
              v-model="$store.state.letterData['bottom-notice']"
              placeholder="محتوى الرسالة"
              rows="10"
              max-rows="6"
            ></b-form-textarea>
          </b-input-group>
          <b-input-group prepend="الختام" class="mt-2 mb-2">
            <b-form-input
              v-model="$store.state.letterData.sender.custom3"
            ></b-form-input>
          </b-input-group>
          <b-button variant="secondary" @click="getPDFPath()">{{
            createInvoiceBtn
          }}</b-button>
          <b-button variant="secondary" @click="downloadInvoice()">{{
            downloadInvoiceBtn
          }}</b-button>
        </b-card>
        <!-- {{ letterData }} -->
        <!-- <b-img src="/img/Riasla_Logo.svg" class="filter-white" /> -->
      </b-col>
      <b-col cols="12" md="6">
        <iframe :src="pdfsrc" style="width: 100%; height: 1000px; border: none">
          Oops! an error has occurred.
        </iframe>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import easyinvoice from "easyinvoice";

export default {
  name: "RisalaPage",
  data() {
    return {
      invoiceBase64: "",
      createInvoiceBtn: "اصدار الرسالة",
      downloadInvoiceBtn: "تنزيل الرسالة",
      pdfsrc: null,
    };
  },
  computed: {
    letterData() {
      return this.$store.state.letterData;
    },
  },
  mounted() {
    if (localStorage.letterData) {
      // console.log("data here", JSON.parse(localStorage.letterData));
      this.$store.commit("changeData", JSON.parse(localStorage.letterData));
    }
    this.getPDFPath();
  },
  methods: {
    readtemplate: function (FileObject) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.base64 = event.target.result;
      };
      reader.readAsDataURL(FileObject);
    },
    async createInvoice() {
      // See documentation for all data properties
      const data = this.letterData;
      const result = await easyinvoice.createInvoice(data);
      this.invoiceBase64 = result.pdf;
    },
    async downloadInvoice() {
      // See documentation for all data properties
      const data = this.letterData;
      const result = await easyinvoice.createInvoice(data);
      easyinvoice.download("رسالة.pdf", result.pdf);
      //	you can download like this as well:
      //	easyinvoice.download();
      //	easyinvoice.download('myInvoice.pdf');
    },
    async renderInvoice() {
      // See documentation for all data properties
      document.getElementById("pdf").innerHTML = "loading...";
      const data = this.letterData;
      const result = await easyinvoice.createInvoice(data);
      easyinvoice.render("pdf", result.pdf);
    },
    async getPDFPath() {
      //   return axios
      //   .get('/sample-3pp.pdf', {
      //     responseType: "blob"
      //   })
      //   .then(response => {
      //     console.log("Success", response);
      //     const blob = new Blob([response.data]);
      //     const objectUrl = URL.createObjectURL(blob);
      //     this.pdfsrc = objectUrl;
      //   })
      //  .catch(console.error); //

      this.$store.commit("changeData", this.letterData);
      localStorage.letterData = JSON.stringify(this.letterData);
      const data = this.letterData;
      const result = await easyinvoice.createInvoice(data);
      this.pdfsrc = "data:application/pdf;base64," + result.pdf;
    },
  },
  created() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.input-group-prepend {
  width: 120px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
