<template>
  <b-container fluid="sm">
    <b-row class="mt-2 mb-2" dir="RTL">
      <b-col cols="12">
        <b-card bg-variant="light">
          <!-- Company Name -->
          <b-input-group prepend="اسم الشركة" class="mt-2 mb-2">
            <b-form-input
              v-model="$store.state.letterData.sender.company"
            ></b-form-input>
          </b-input-group>
          <!-- address  -->
          <b-input-group prepend="عنوان الشركة" class="mt-2 mb-2">
            <b-form-input
              v-model="$store.state.letterData.sender.address"
            ></b-form-input>
          </b-input-group>
          <!-- city  -->
          <b-input-group prepend="مدينة الشركة" class="mt-2 mb-2">
            <b-form-input
              v-model="$store.state.letterData.sender.city"
            ></b-form-input>
          </b-input-group>
          <!-- country  -->
          <b-input-group prepend="بلد الشركة" class="mt-2 mb-2">
            <b-form-input
              v-model="$store.state.letterData.sender.country"
            ></b-form-input>
          </b-input-group>

          <b-input-group prepend="الاسم" class="mt-2 mb-2">
            <b-form-input
              v-model="$store.state.letterData.sender.custom1"
            ></b-form-input>
          </b-input-group>

          <b-input-group prepend="الوظيفة" class="mt-2 mb-2">
            <b-form-input
              v-model="$store.state.letterData.sender.custom2"
            ></b-form-input>
          </b-input-group>

          <!-- Logo -->
          <b-input-group prepend="شعار الشركة" class="mt-2 mb-2">
            <b-form-input
              v-model="$store.state.letterData.images.logo"
            ></b-form-input>
          </b-input-group>

          <!-- Background -->
          <b-input-group prepend="خلفية الشركة" class="mt-2 mb-2">
            <b-form-input
              v-model="$store.state.letterData.images.background"
            ></b-form-input>
          </b-input-group>

          <b-button variant="secondary" @click="saveData()">
            تخزين البيانات</b-button
          >
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "AppSettings",
  data() {
    return {};
  },
  computed: {
    letterData() {
      return this.$store.state.letterData;
    },
  },
  mounted() {
    if (localStorage.letterData) {
      // console.log("data here", JSON.parse(localStorage.letterData));
      this.$store.commit("changeData", JSON.parse(localStorage.letterData));
    }
  },
  methods: {
    saveData() {
      this.$store.commit("changeData", this.letterData);
      localStorage.letterData = JSON.stringify(this.letterData);
    },
  },
  created() {
    // this.saveData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.input-group-prepend {
  width: 120px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
