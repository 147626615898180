import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
// import fonts from "../assets/fonts.html";
import RisalaTemplate from "../components/RisalaTemplate.html";
export default new Vuex.Store({
  state: {
    letterData: {
      customize: {
        //template: "SGVsbG8gd29ybGQh", // Must be base64 encoded html. This example contains 'Hello World!' in base64
        // template: require("fs").readFileSync("template.html", "base64"), // Must be base64 encoded html
        // template: template, // Must be base64 encoded html
        // template: btoa(fonts) + btoa(RisalaTemplate),
        template: btoa(RisalaTemplate),
      },
      images: {
        logo: "",
        background: "",
        // logo: "https://img.freepik.com/vektoren-kostenlos/vogel-bunter-logo-gradientenvektor_343694-1365.jpg",
        // background: "https://nuqta.ly/Nuqta_Letterhead.pdf",
      },
      sender: {
        company: "اسم شركة المرسل",
        address: "عنوان شركة المرسل",
        city: "مدينة المرسل",
        country: "بلد المرسل",
        custom1: "اسم المرسل",
        custom2: "وظيفة المرسة",
        custom3: "والسلام عليكم ورحمة الله وبركاته",
      },
      client: {
        company: "اسم شركة المستقبل",
        address: "عنوان شركة المستقبل",
        city: "مدينة المستقبل",
        country: "بلد المستقبل",
        custom1: "الاخوة: //",
        custom2: "ادارة ......",
        // "custom3": "custom value 3"
      },
      information: {
        number: null,
        date: new Date().toISOString().replace(/T.*/, "").split("-").join("-"),
        // "due-date": "31-12-2021",
      },
      "bottom-notice":
        "بعد التحية ،،،،\n\nمن هذه المنصة يمكنك كتابة رسالة بشكل سريع باستخدام اي جهاز.\n\nويمكن بالاعدادات اضافة خلفية للرسالة واضافة شعار الشركة واسم الجهة المرسلة للرسالة.",
      settings: {
        currency: "Lyd", // See documentation 'Locales and Currency' for more info. Leave empty for no currency.
        locale: "ar-LY", // Defaults to en-US, used for number formatting (see docs)
        // "margin-top": 25, // Default to 25
        // "margin-right": 25, // Default to 25
        // "margin-left": 25, // Default to 25
        // "margin-bottom": 25, // Default to 25
        // "format": "Letter", // Defaults to A4,
        // "height": "1000px", // allowed units: mm, cm, in, px
        // "width": "500px", // allowed units: mm, cm, in, px
        // "orientation": "landscape", // portrait or landscape, defaults to portrait
      },
      // Used for translating the headers to your preferred language
      // Defaults to English. Below example is translated to Dutch
      translate: {
        invoice: "فاتورة",
        number: "الرقم الإشاري",
        date: "التاريخ",
        //     "due-date": "Verloopdatum",
        //     "subtotal": "Subtotaal",
        products: "وصف المنتج",
        quantity: "العدد",
        price: "السعر",
        "product-total": "الاجمالي",
        total: "القيمة الاجمالية",
        //		 "vat": "btw"
      },
    },
  },
  getters: {},
  mutations: {
    changeData(state, payload) {
      state.letterData = payload;
      state.letterData.customize.template = btoa(RisalaTemplate);
      //console.log(payload);
    },
  },
  actions: {},
  modules: {},
});
