<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->

    <Risala msg="" />
  </div>
</template>

<script>
// @ is an alias to /src

import Risala from "@/components/Risala.vue";

export default {
  name: "HomeView",
  components: {
    Risala,
  },
};
</script>
